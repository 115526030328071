import '@Application/Bootstrap';

import autoload from '@123/druid/dist/Framework/Decorators/Autoload';
import component from '@123/druid/dist/Framework/Decorators/Component';
import View from '@123/druid/dist/Framework/View/View';

@autoload
@component
// This view exists as a default for any module that does not have an application view.
// No logic or code may be added here. Any logic needs to be added via modules.
export default class DefaultModuleView extends View {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super();
    }
}
